import { Container } from "@components/ui";
import { useRouter } from "next/router";
import { URL_PAGE_CART } from "@middleware/constants";
import { BottomFooter } from "./parts/BottomFooter/BottomFooter";

export const Footer = () => {
  const router = useRouter();
  const isCheckout = router.pathname === URL_PAGE_CART;

  return (
    <footer id="lk-footer">
      <Container>{isCheckout ? null : <BottomFooter />}</Container>
    </footer>
  );
};
